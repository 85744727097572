body {
  background-color: black;
}

@font-face {
  font-family: 'equinox';
  src: url('~assets/fonts/equinox_regular.otf'),
    url('~assets/fonts/equinox_regular-webfont.woff2'),
    url('~assets/fonts/equinox_regular-webfont.woff');
  font-weight: normal;
  font-style: normal;
}

.aftercamp-header {
  font-family: 'equinox';
  margin-bottom: 0px;
  margin-right: -40px;
  letter-spacing: 40px;
  text-shadow: 0px 0px 8px #fff;
}

.aftercamp-clearfont h5 {
  font-weight: 400;
}

.aftercamp-social-icon {
  font-size: 32px !important;
}

@media (min-width: 0px) and (max-width: 960px) {
  .aftercamp-header {
    margin-bottom: 0px;
    margin-right: -10px;
    letter-spacing: 10px;
  }
  .aftercamp-footer {
    float: left;
    margin-top: 10px;
  }
}
